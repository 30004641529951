<div class="sidebar-wrapper">
    <div class="logo">
        <a href="https://fazprev.com.br/" class="simple-text logo-mini">
            <div class="logo-image-small">
                <img src="./assets/img/logo.png">
            </div>
        </a>
        <a href="https://fazprev.com.br/" target="_blank" class="simple-text logo-normal">
            Atendimento
        </a>
    </div>
    <ul class="nav">
        <li *ngFor="let menuItem of menuItems" routerLinkActive="active" class="{{menuItem.class}}">
            <a [routerLink]="[menuItem.path]">
                <i class="nc-icon {{menuItem.icon}}"></i>
                <p>{{menuItem.title}}</p>
            </a>
        </li>
        <hr />
        <li>
            <a href="#" (click)="logout()">
                <i class="nc-icon nc-share-66"></i>
                <p>Sair</p>
            </a>
        </li>
    </ul>
</div>