<div class="wrapper" style="background-color: rgb(196, 196, 196);">
    <div class="container">
        <form autocomplete="off" [formGroup]="form" (ngSubmit)="addUser()">
            <div class="row">
                <div class="col-lg-7 col-md-6 col-sm-6 ml-auto mr-auto mt-5">
                    <div class="card">
                        <div class="card-header text-center">
                            <img src="./assets/img/logo.png" width="50" height="50">
                            <h4 class="card-title">Cadastrar-se</h4>
                        </div>
                        <div class="card-body">
                            <p class="card-description text-center">Informe seus dados para efetuar o cadastro</p>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Nome</label>
                                        <input type="text" class="form-control"
                                            placeholder="Nome do usuário" formControlName="nome">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>CPF</label>
                                        <input type="text" class="form-control" mask="000.000.000-00"
                                            placeholder="CPF do usuário" formControlName="cpf">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Usuário</label>
                                        <input type="email" class="form-control"
                                            placeholder="seu_email@email.com.br" formControlName="email">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Senha</label>
                                        <input type="password" class="form-control"
                                            placeholder="Sua senha de acesso" formControlName="senha">
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer text-center">
                                <button type="submit" class="btn btn-link btn-lg"
                                    [disabled]="!form.valid">Concluir</button>
                                <br />
                                <a href="#" [routerLink]="['/login']">Voltar a tela de acesso</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>