import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecoverPasswordService {

  url: string;

  private http: HttpClient;

  constructor(httpBackend: HttpBackend) {
    this.http = new HttpClient(httpBackend);
    this.url = `${environment.apiUrl}/cadastro`;
  }

  async recoverPassword(email: string): Promise<void> {
    const response = await this.http.put<void>(`${this.url}/${email}/recuperar-senha`, null)
      .toPromise();

    return response;
  }
}
