<div class="wrapper" style="background-color: rgb(196, 196, 196);">
    <div class="container">
        <form autocomplete="off" [formGroup]="form" (ngSubmit)="login()">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6 ml-auto mr-auto mt-5">

                    <div class="alert alert-warning" role="alert">
                        ATENÇÃO: Para os beneficiários que já possuiam acesso, antes de entrar no sistema é necessário recuperar a senha!
                    </div>

                    <div class="card">
                        <div class="card-header text-center">
                            <img src="./assets/img/logo.png" width="50" height="50">
                            <h4 class="card-title">Acesso</h4>
                        </div>
                        <div class="card-body">
                            <p class="card-description text-center">Informe seus dados para acesso</p>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Usuário</label>
                                        <input type="email" class="form-control" placeholder="seu_email@email.com.br"
                                            formControlName="usuario">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="text-right">
                                        <a href="#" [routerLink]="['/recover-password']">Esqueçeu sua senha?</a>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Senha</label>
                                        <input type="password" class="form-control" placeholder="Sua senha de acesso"
                                            formControlName="senha">
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="text-center">
                                        <a href="#" [routerLink]="['/register']">Ainda não possui acesso?</a>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer text-center">
                                <button type="submit" class="btn btn-link btn-lg" [disabled]="!form.valid">
                                    Entrar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>