import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from './../../environments/environment';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) { }

  async logout(): Promise<void> {
    let options = {
      withCredentials: true
    }

    await this.http.delete(`${environment.apiUrl}/tokens/revoke`, options)
      .toPromise();
    this.auth.limparAccessToken();
  }
}
