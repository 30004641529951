import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';

import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  jwtPayload: any;
  httpHeaders: HttpHeaders;

  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService
  ) {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic YW5ndWxhcjpAbmd1bEByMA=='
    });

    this.carregarToken();
  }

  async login(login: string, senha: string): Promise<void> {
    let options = {
      headers: this.httpHeaders,
      withCredentials: true
    }

    const body = `username=${login}&password=${senha}&grant_type=password`;

    try {
      const response = await this.http.post<any>(`${environment.apiUrl}/oauth/token`, body, options)
        .toPromise();
      this.armazenarToken(response['access_token']);
    } catch (e) {
      if (e.status === 0) {
        return Promise.reject("Não conseguimos acessar o servidor, tente novamente mais tarde!");
      }

      if (e.status === 400) {
        const msg = e.error;
        if (msg.error === 'invalid_grant') {
          return Promise.reject(msg.error_description);
        }
      }
      return await Promise.reject(e);
    }

  }

  async obterNovoAccessToken(): Promise<void> {
    let options = {
      headers: this.httpHeaders,
      withCredentials: true
    }

    const body = 'grant_type=refresh_token';

    try {
      const response = await this.http.post<any>(`${environment.apiUrl}/oauth/token`, body, options)
        .toPromise();
      this.armazenarToken(response['access_token']);
      return await Promise.resolve();
    } catch (e) {
      return await Promise.resolve(null);
    }

  }

  limparAccessToken() {
    localStorage.removeItem('token');
    this.jwtPayload = null;
  }

  isAccessTokenInvalido() {
    const token = localStorage.getItem('token');
    return !token || this.jwtHelper.isTokenExpired(token);
  }

  private armazenarToken(token: string) {
    localStorage.setItem('token', token);
    this.jwtPayload = this.jwtHelper.decodeToken(token);
  }

  private carregarToken() {
    const token = localStorage.getItem('token');
    if (token) {
      this.armazenarToken(token);
    }
  }

  temPermissao(permissao: string) {
    return this.jwtPayload && this.jwtPayload.authorities.includes(permissao);
  }

  temQualquerPermissao(roles: any) {
    for (const role of roles) {
      if (this.temPermissao(role)) {
        return true;
      }
    }
    return false;
  }
}
