<div class="wrapper">
    <div class="sidebar" data-color="white" data-active-color="success">
        <en-sidebar></en-sidebar>
    </div>
    <div class="main-panel">
        <en-navbar></en-navbar>
        <div class="content">
            <router-outlet></router-outlet>
        </div>
        <en-footer></en-footer>
    </div>
</div>