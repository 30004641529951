import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './security/auth.guard';

import { ContentComponent } from './layout/content/content.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: '', component: ContentComponent, children: [
      { path: '', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule), canActivate: [AuthGuard] },
      { path: '', loadChildren: () => import('./shared/shared.module').then(m => m.SharedModule), canActivate: [AuthGuard] },
      { path: '', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard] },
      { path: '', loadChildren: () => import('./page/page.module').then(m => m.PageModule), canActivate: [AuthGuard] }
    ]
  },

  { path: '**', redirectTo: 'not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
