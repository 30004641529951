import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ErrorHandlerService } from './../../core/error-handler.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'en-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  form: FormGroup;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      usuario: [null, [Validators.required, Validators.maxLength(150), Validators.email]],
      senha: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(8)]]
    });
  }

  login(): void {
    this.auth.login(this.form.get('usuario').value, this.form.get('senha').value)
      .then(() => {
        this.router.navigate(['/dashboard']);
      })
      .catch(error => {
        this.errorHandler.handle(error);
      });
  }

}
