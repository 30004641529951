<footer class="footer">
    <div class="container-fluid">
        <nav class="pull-left">
            <ul>
                <li>
                    <a href="https://fazprev.com.br/" target="_blank">
                        Instituto de Prev. Mun. de Fazenda Rio Grande - FAZPREV
                    </a>
                </li>
            </ul>
        </nav>
        <div class="copyright pull-right">
            &copy;
            {{ dataAtual | date: 'yyyy'}}, todos os direitos reservados.
        </div>
    </div>
</footer>