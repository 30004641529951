<nav class="navbar navbar-expand-lg navbar-absolute fixed-top navbar-transparent">
    <div class="container-fluid">
        <div class="navbar-wrapper">
            <div class="navbar-toggle">
                <button type="button" class="navbar-toggler" (click)="sidebarToggle()">
                    <span class="navbar-toggler-bar bar1"></span>
                    <span class="navbar-toggler-bar bar2"></span>
                    <span class="navbar-toggler-bar bar3"></span>
                </button>
            </div>
            <a class="navbar-brand" href="javascript:void(0)">{{getTitle()}}</a>
        </div>
        <button class="navbar-toggler" type="button" (click)="collapse()" [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseAtendimento">
            <span class="navbar-toggler-bar navbar-kebab"></span>
            <span class="navbar-toggler-bar navbar-kebab"></span>
            <span class="navbar-toggler-bar navbar-kebab"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="collapseAtendimento" [ngbCollapse]="isCollapsed">
                <ul class="navbar-nav">
                <li class="nav-item btn-rotate" ngbDropdown placement="bottom-left">
                    <a class="nav-link" ngbDropdownToggle id="navbarDropdownMenuLink">
                        <i class="nc-icon nc-settings-gear-65"></i>
                        <p>
                            <span class="d-lg-none d-md-block">Administrador</span>
                        </p>
                    </a>
                    <div ngbDropdownMenu aria-labelledby="navbarDropdownMenuLink"
                        class="dropdown-menu dropdown-menu-right">
                        <a ngbDropdownItem href="javascript:void(0)" [routerLink]="['/type-requests']" >Tipo de solicitação</a>
                        <a ngbDropdownItem href="javascript:void(0)" [routerLink]="['/type-requests']" >Trocar senha de usuário</a>
                    </div>
                </li>
                <li class="nav-item btn-rotate" ngbDropdown placement="bottom-left">
                    <a class="nav-link" ngbDropdownToggle id="navbarDropdownMenuLink">
                        <i class="nc-icon nc-bell-55"></i>
                        <p>
                            <span class="d-lg-none d-md-block">Notificações</span>
                        </p>
                    </a>
                    <div ngbDropdownMenu aria-labelledby="navbarDropdownMenuLink"
                        class="dropdown-menu dropdown-menu-right">
                        <a ngbDropdownItem href="javascript:void(0)">Nenhuma notificação</a>
                    </div>
                </li>
                <li class="nav-item">
                    <a class="nav-link btn-rotate" href="javascript:void(0)" (click)="logout()">
                        <i class="nc-icon nc-share-66"></i>
                        <p>
                            <span class="d-lg-none d-md-block">Sair</span>
                        </p>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>