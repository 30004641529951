import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { NotificationsService } from './notifications.service';


@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(private notification: NotificationsService) { }

  handle(errorResponse: any) {
    let msg: string;

    if (typeof errorResponse === 'string') {
      msg = errorResponse;

    } else if (errorResponse instanceof HttpErrorResponse
      && errorResponse.status >= 400 && errorResponse.status <= 499) {

      msg = 'Ocorreu um erro ao processar a sua solicitação';

      if (errorResponse.status === 403) {
        msg = 'Você não tem permissão para executar esta ação';
      }

      try {
        msg = errorResponse.error.mensagemUsuario;
      } catch (e) { }

    } else {
      msg = 'Erro ao processar serviço remoto. Tente novamente.';
    }

    this.notification.notificacao('error', msg);

  }
}
