import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class UtilFormsService {

  constructor() { }

  validField(form: FormGroup, field: any) {
    const valid = Boolean(!form.get(field).valid && form.get(field).touched);

    if (valid) {
      return {
        'is-invalid': valid
      }
    } else if (!valid && form.get(field).value != null) {
      return {
        'is-valid': !valid
      }
    }
  }

  pagination(size: number, currentPage: number, totalElements: number, last: boolean): string {
    let totalItensPorPagina: number = 0;

    totalItensPorPagina = size * ((currentPage + 1) - 1) + 1;

    if (totalItensPorPagina >= totalElements) {
      totalItensPorPagina = totalElements;
    }
    if ((totalItensPorPagina <= totalElements) && (last === true)) {
      size = totalElements;
    }

    return totalItensPorPagina + "-" + size + " de " + totalElements;
  }
}
