import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ErrorHandlerService } from './../../core/error-handler.service';
import { NotificationsService } from 'src/app/core/notifications.service';
import { RecoverPasswordService } from './recover-password.service';

@Component({
  selector: 'en-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.css']
})
export class RecoverPasswordComponent implements OnInit {
  
  form: FormGroup;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private recoverPassword: RecoverPasswordService,
    private notifications: NotificationsService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      usuario: [null, [Validators.required, Validators.maxLength(150), Validators.email]]
    });
  }

  recover(): void {
    this.recoverPassword.recoverPassword(this.form.get('usuario').value)
    .then(result => {
      this.reset();
      this.back();
      this.notifications.notificacao('success', 'Email enviado com sucesso!');
    })
    .catch(error => this.errorHandler.handle(error));
  }

  reset() {
    this.form.reset();
  }

  back() {
    this.router.navigate(['/dashboard']);
  }

}
