import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ErrorHandlerService } from './../../core/error-handler.service';
import { NotificationsService } from 'src/app/core/notifications.service';
import { RegisterService } from './register.service';

@Component({
  selector: 'en-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  form: FormGroup;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private registerService: RegisterService,
    private notifications: NotificationsService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      nome: [null, Validators.required],
      cpf: [null, Validators.required],
      email: [null, [Validators.required, Validators.maxLength(150), Validators.email]],
      senha: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(8)]]
    });
  }

  addUser() {
    this.registerService.addUser(this.form.value)
      .then(result => {
        this.reset();
        this.back();
        this.notifications.notificacao('success', 'Usuário adicionado com sucesso!');
      })
      .catch(error => this.errorHandler.handle(error));
  }

  reset() {
    this.form.reset();
  }

  back() {
    this.router.navigate(['/dashboard']);
  }

}
