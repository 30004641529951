import { NgModule, LOCALE_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { deLocale } from 'ngx-bootstrap/locale';

import localePt from '@angular/common/locales/pt';

import { ErrorHandlerService } from './error-handler.service';
import { NotificationsService } from './notifications.service';
import { UtilFormsService } from './util-forms.service';
import { AuthService } from '../security/auth.service';

registerLocaleData(localePt);
defineLocale('pt-br', deLocale);

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    NotificationsService,
    ErrorHandlerService,
    UtilFormsService,
    AuthService,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' }
  ]
})
export class CoreModule { }
