import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { User } from 'src/app/core/model/user.model';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  url: string;
  private http: HttpClient;

  constructor(httpBackend: HttpBackend) {
    this.http = new HttpClient(httpBackend);
    this.url = `${environment.apiUrl}/cadastro`;
  }

  async addUser(user: User): Promise<User> {
    const response = await this.http.post<User>(this.url, user)
      .toPromise();

    return response;
  }
}
