import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { LogoutService } from 'src/app/security/logout.service';

export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Painel Principal', icon: 'nc-bank', class: '' },
  { path: '/profile', title: 'Perfil do Usuário', icon: 'nc-single-02', class: '' },
  { path: '/chage-password', title: 'Trocar senha', icon: 'nc-key-25', class: '' },
  { path: '/requests', title: 'Solicitações', icon: 'nc-box', class: '' },
  //{ path: '/order', title: 'Pedidos', icon: 'nc-app', class: '' },
  { path: '/payslip', title: 'Holerite', icon: 'nc-money-coins', class: '' }
];

@Component({
  selector: 'en-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  
  public menuItems: any[];

  constructor(
    private router: Router,
    private logoutService: LogoutService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }

  logout(): void {
    this.logoutService.logout()
      .then(() => {
        this.router.navigate(['/login']);
      })
      .catch(error => {
        this.errorHandler.handle(error);
      });
  }

}
